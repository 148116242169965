import "./about.scss";
import BackButton from "../components/BackButton";


export default function About(){



    return(
        <div id="about-container">

            <div style={{padding: '18px 0 0 18px'}}>
                <BackButton />
            </div>

            <div id='about-content'>
                <div id="about-title">
                    「关于藏品」
                </div>

                <div>
                    <p>NFT创意作品具有作品的唯一性、不可分割性以及不可被篡改的特点，此商品具有特殊性，因此不支持退换，谢谢您的理解与配合！</p>

                    <p>
                        上述NFT创意作品的知识产权由珊瑚创品（宁夏）酒业有限公司有限公司拥有，NFT的所有权人或者合法拥有者不得将上述NFT创意作品用于任何商业途径以及任何非法途径。
                    </p>

                    <p>
                        本网站仅代为展示和提取NFT创意作品，严格禁止自然人、法人或非法人组织利用NFT创意作品从事炒作、场外交易、欺诈等非法行为或以任何非法方式使用。
                    </p>

                </div>
            </div>

        </div>
    )
}